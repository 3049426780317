export default [
  {
    text: 'venue.table.name',
    value: 'name',
    default: true,
    defaultOnMobile: true,
  },
  {
    text: 'venue.table.shortId',
    value: 'readableId',
    default: true,
    defaultOnMobile: true,
  },
  {
    text: 'venue.table.email',
    value: 'email',
    default: true,
  },
  {
    text: 'venue.table.street',
    value: 'street',
    default: true,
  },
  {
    text: 'venue.table.isServiceActivated',
    value: 'isServiceActivated',
    default: true,
    defaultOnMobile: true,
    type: 'slot',
  },
  {
    text: 'venue.table.piggyLoyaltyEnabled',
    value: 'piggyLoyaltyEnabled',
    default: false,
    type: 'slot',
  },
  {
    text: 'venue.table.isPublished',
    value: 'isPublished',
    default: false,
    type: 'slot',
    defaultOnMobile: true,
  },
  {
    text: 'venue.table.number',
    value: 'number',
    default: true,
  },
  {
    text: 'venue.table.legalForm',
    value: 'legalForm',
    default: false,
  },
  {
    text: 'venue.table.locType',
    value: 'locType',
    default: false,
  },
  {
    text: 'venue.table.city',
    value: 'city',
    default: true,
  },
  {
    text: 'venue.table.vatNumber',
    value: 'vatNumber',
    default: false,
  },
  {
    text: 'venue.table.vatNumberGermany',
    value: 'vatNumberGermany',
    default: false,
  },
  {
    text: 'venue.table.webAppSlug',
    value: 'webAppSlug',
    default: true,
    defaultOnMobile: false,
  },
  {
    text: 'venue.table.customerGroup',
    value: 'customerGroup',
    default: true,
  },
  {
    text: 'venue.table.checkoutIntegration',
    value: 'checkoutIntegration',
    default: true,
  },
  {
    text: 'venue.table.externalOrderProviderStatus',
    value: 'externalOrderProviderStatus',
    default: true,
    virtual: true,
    type: 'slot',
  },
  {
    text: 'venue.table.masterId',
    value: 'masterId',
    default: false,
  },
];
